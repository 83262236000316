/* eslint-disable xss/no-mixed-html */
import { defineMessages } from 'react-intl'

const messages = defineMessages({
  accessToken: {
    id: 'Pro.AccessToken',
    defaultMessage: 'Access token',
  },
  eventViewsWebsiteOnly: {
    id: 'Pro.EventViewsWebsiteOnly',
    defaultMessage: 'Event views (website only)',
  },
  raPro: {
    id: 'Pro.RAPro',
    defaultMessage: 'RA Pro',
  },
  raProNews: {
    id: 'Magazine.RAProNews',
    defaultMessage: 'RA Pro news',
  },
  raProUpdates: {
    id: 'Pro.RAProUpdates',
    defaultMessage: 'RA Pro Updates',
  },
  bumpCampaign: {
    id: 'Pro.BumpCampaign',
    defaultMessage: 'Bump Campaign',
  },
  howBumpsWork: {
    id: 'Pro.HowBumpsWork',
    defaultMessage: 'How Bumps work',
  },
  viewCampaign: {
    id: 'Pro.ViewCampaign',
    defaultMessage: 'View campaign',
  },
  newTag: {
    id: 'Pro.NewTag',
    defaultMessage: 'New',
  },
  bumpListing: {
    id: 'Pro.BumpListing',
    defaultMessage:
      'Bump your listing to position 3 in the iOS version of the RA Guide',
  },
  bumpListingWeb: {
    id: 'Pro.BumpListingWeb',
    defaultMessage:
      'Bump your listing to position 3 on ra.co and the iOS RA Guide',
  },
  campaignInfo: {
    id: 'Pro.CampaignInfo',
    defaultMessage:
      'You can begin a campaign up until the start time for this event.',
  },
  startBumpCampaign: {
    id: 'Pro.StartBumpCampaign',
    defaultMessage: 'Start campaign',
  },
  editBumpCampaign: {
    id: 'Pro.EditBumpCampaign',
    defaultMessage: 'Edit campaign',
  },
  saveChanges: {
    id: 'Pro.SaveChanges',
    defaultMessage: 'Save changes',
  },
  revenue: {
    id: 'Pro.Revenue',
    defaultMessage: 'Revenue',
  },
  ticketsSold: {
    id: 'Pro.TicketsSold',
    defaultMessage: 'Tickets sold',
  },
  yourNextEvent: {
    id: 'Pro.YourNextEvent',
    defaultMessage: 'Your next event',
  },
  manageEventsAndTickets: {
    id: 'Pro.ManageEventsAndTickets',
    defaultMessage: 'Manage Events and Tickets',
  },
  manageTickets: {
    id: 'Pro.ManageTickets',
    defaultMessage: 'Manage tickets',
  },
  noEvents: {
    id: 'Pro.NoEvents',
    defaultMessage: "You haven't submitted any events",
  },
  eventHeader: {
    id: 'Pro.eventHeader',
    defaultMessage: 'Event',
  },
  ticketsSoldAllocated: {
    id: 'Pro.TicketsSoldAllocated',
    defaultMessage: 'Tickets sold / allocated',
  },
  managePromoters: {
    id: 'Pro.ManagePromoters',
    defaultMessage: 'Manage promoters',
  },
  noManagePromoters: {
    id: 'Pro.NoManagePromoters',
    defaultMessage: "You haven't added any promoters yet",
  },
  noArtists: {
    id: 'Pro.NoArtists',
    defaultMessage: "You haven't added any artists yet",
  },
  manageArtists: {
    id: 'Pro.ManageArtists',
    defaultMessage: 'Manage artists',
  },
  noUpcomingEvents: {
    id: 'Pro.NoUpcomingEvents',
    defaultMessage: 'No upcoming events',
  },
  addNewPromoter: {
    id: 'Pro.AddNewPromoter',
    defaultMessage: 'Add new promoter',
  },
  addNewArtist: {
    id: 'Pro.AddNewArtist',
    defaultMessage: 'Add new artist',
  },
  pageviews: {
    id: 'Pro.Pageviews',
    defaultMessage: 'Pageviews',
  },
  manageLabels: {
    id: 'Pro.ManageLabels',
    defaultMessage: 'Manage labels',
  },
  noManageLabels: {
    id: 'Pro.NoManageLabels',
    defaultMessage: "You haven't added any labels yet",
  },
  addNewLabel: {
    id: 'Pro.AddNewLabel',
    defaultMessage: 'Add new label',
  },
  eventsAndTickets: {
    id: 'Pro.EventsAndTickets',
    defaultMessage: 'Events & tickets',
  },
  howto: {
    id: 'Pro.HowTo',
    defaultMessage: 'How To',
  },
  status: {
    id: 'Dict.Status',
    defaultMessage: 'Status',
  },
  live: {
    id: 'Dict.Live',
    defaultMessage: 'Live',
  },
  submitAnEvent: {
    id: 'Pro.SubmitAnEvent',
    defaultMessage: 'Submit an event',
  },
  submitAnotherEvent: {
    id: 'Pro.SubmitAnotherEvent',
    defaultMessage: 'Submit another event',
  },
  updateEvent: {
    id: 'Pro.UpdateEvent',
    defaultMessage: 'Update event',
  },
  duplicateEvent: {
    id: 'Pro.DuplicateEvent',
    defaultMessage: 'Duplicate event',
  },
  basic: {
    id: 'Dict.Basic',
    defaultMessage: 'Basic',
  },
  eventName: {
    id: 'Dict.EventName',
    defaultMessage: 'Event name',
  },
  eventStartDate: {
    id: 'Dict.EventStarts',
    defaultMessage: 'Event starts',
  },
  eventStartTime: {
    id: 'Dict.StartTime',
    defaultMessage: 'Start time',
  },
  eventEndDate: {
    id: 'Dict.EventEnd',
    defaultMessage: 'Event end',
  },
  eventEndTime: {
    id: 'Dict.EndTime',
    defaultMessage: 'End time',
  },
  venue: {
    id: 'Dict.Venue',
    defaultMessage: 'Venue',
  },
  venueTBA: {
    id: 'Dict.VenueTBA',
    defaultMessage: 'Venue TBA',
  },
  venueNotInSystem: {
    id: 'Dict.VenueNotInSystem',
    defaultMessage: 'Sorry, looks like that venue is not in our system.',
  },
  createVenue: {
    id: 'Dict.CreateVenue',
    defaultMessage: 'Create a new venue',
  },
  promoterNotInSystem: {
    id: 'Dict.PromoterNotInSystem',
    defaultMessage: 'Sorry, looks like that promoter is not in our system.',
  },
  createPromoter: {
    id: 'Dict.CreatePromoter',
    defaultMessage: 'Create a new promoter',
  },
  viewPromoterPage: {
    id: 'Dict.ViewPromoterPage',
    defaultMessage: 'View promoter page',
  },
  details: {
    id: 'Dict.Details',
    defaultMessage: 'Details',
  },
  eventDescription: {
    id: 'Dict.EventDescription',
    defaultMessage: 'Event Description',
  },
  eventDescriptionInputPlaceholder: {
    id: 'Dict.EventDescriptionInputPlaceholder',
    defaultMessage: 'Use this space to tell the world about your event.',
  },
  age: {
    id: 'Dict.Age',
    defaultMessage: 'Age',
  },
  cost: {
    id: 'Dict.Cost',
    defaultMessage: 'Cost',
  },
  other: {
    id: 'Dict.Other',
    defaultMessage: 'Other',
  },
  promoter: {
    id: 'Dict.Promoter',
    defaultMessage: 'Promoter',
  },
  promoters: {
    id: 'Dict.Promoters',
    defaultMessage: 'Promoters',
  },
  primaryPromoter: {
    id: 'Dict.PrimaryPromoter',
    defaultMessage: 'Primary promoter',
  },
  otherPromoters: {
    id: 'Dict.OtherPromoters',
    defaultMessage: 'Other promoter(s)',
  },
  promotionalLinks: {
    id: 'Dict.promotionalLinks',
    defaultMessage: 'Promotional Links',
  },
  images: {
    id: 'Dict.Images',
    defaultMessage: 'Images',
  },
  promotionalLink: {
    id: 'Dict.promotionalLink',
    defaultMessage: 'Promotional Link',
  },
  websiteName: {
    id: 'Dict.WebsiteName',
    defaultMessage: 'Website name',
  },
  websiteUrl: {
    id: 'Dict.WebsiteUrl',
    defaultMessage: 'Website URL',
  },
  websiteNameInputPlaceholder: {
    id: 'Dict.WebsiteNameInputPlaceholder',
    defaultMessage: 'The website',
  },
  websiteUrlInputPlaceholder: {
    id: 'Dict.WebsiteUrlInputPlaceholder',
    defaultMessage: 'https://website.com',
  },
  whoCanSeeYourEvent: {
    id: 'Dict.WhoCanSeeYourEvent',
    defaultMessage: 'Who can see your event?',
  },
  publishNow: {
    id: 'Dict.PublishNow',
    defaultMessage: 'Publish now',
  },
  embargoUntil: {
    id: 'Dict.EmbargoUntil',
    defaultMessage: 'Embargo Until',
  },
  promotional: {
    id: 'Dict.Promotional',
    defaultMessage: 'Promotional',
  },
  venueTba: {
    id: 'Pro.VenueTba',
    defaultMessage: 'Venue TBA',
  },
  venueKnown: {
    id: 'Pro.VenueKnown',
    defaultMessage: 'Venue known',
  },
  lineup: {
    id: 'Pro.Lineup',
    defaultMessage: 'Lineup',
  },
  lineupPlaceholder: {
    id: 'Pro.LineupPlaceholder',
    defaultMessage: 'Start by typing @artist names...',
  },
  lineupExplanation: {
    id: 'Pro.LineupExplanation',
    defaultMessage:
      'Type @ and start searching to tag an artist. You can also paste in your lineup to automatically tag all the artists.',
  },
  pleaseEnterPaymentUserDetails: {
    id: 'Pro.PleaseEnterPaymentUserDetails',
    defaultMessage:
      'Please enter the details of the promoter bank account holder, so we can pay you after your event.',
  },
  stateProvince: {
    id: 'Pro.StateProvince',
    defaultMessage: 'State / province',
  },

  eventApprovalEventFormMessage: {
    id: 'Pro.EventApprovalEventFormMessage',
    defaultMessage:
      'Event visibility settings will be applied once your event is approved',
  },
  eventPendingApprovalMessage: {
    id: 'Pro.eventApprovalStatusMessage',
    defaultMessage:
      "We aim to review events within 24 hours. Once it's approved, we'll notify you by email.",
  },
  profileType: {
    id: 'Pro.ProfileType',
    defaultMessage: 'Profile type',
  },
  businessName: {
    id: 'Pro.BusinessName',
    defaultMessage: 'Business name',
  },
  promoterSocialTracking: {
    id: 'Pro.PromoterSocialTracking',
    defaultMessage: 'Promoter social tracking',
  },
  tracking: {
    id: 'Pro.Tracking',
    defaultMessage: 'Tracking',
  },
  trackingId: {
    id: 'Pro.TrackingId',
    defaultMessage: 'Tracking ID',
  },
  trackingIds: {
    id: 'Pro.TrackingIds',
    defaultMessage: 'Tracking IDs',
  },
  trackingFor: {
    id: 'Pro.TrackingFor',
    defaultMessage: 'Tracking for <promoter>promoter</promoter>',
  },
  trackingCopy: {
    id: 'Pro.TrackingCopy',
    defaultMessage:
      'Tracking events for these tracking IDs will be fired on the home page for <promoter>promoter</promoter>, and any events owned by them.<break></break><break></break>They will also be used to track conversions when a customer checks out.',
  },
  pixelId: {
    id: 'Pro.PixelId',
    defaultMessage: 'Pixel ID',
  },
  accountHolder: {
    id: 'Pro.AccountHolder',
    defaultMessage: 'Account Holder',
  },
  paymentMethod: {
    id: 'Pro.PaymentMethod',
    defaultMessage: 'Payment Method',
  },
  noAccountHolderDetails: {
    id: 'Pro.NoAccountHolderDetails',
    defaultMessage: 'No account holder details',
  },
  noPaymentMethod: {
    id: 'Pro.NoPaymentMethod',
    defaultMessage: 'No payment method',
  },
  sendTestEvent: {
    id: 'Pro.SendTestEvent',
    defaultMessage: 'Send test event',
  },
  testEventCode: {
    id: 'Pro.TestEventCode',
    defaultMessage: 'Test event code',
  },
  testEventSent: {
    id: 'Pro.TestEventSent',
    defaultMessage: 'Test event sent',
  },
  save: {
    id: 'Pro.Save',
    defaultMessage: 'Save',
  },
  savedSuccessfully: {
    id: 'Pro.SavedSuccessfully',
    defaultMessage: 'Settings saved successfully',
  },
  fieldMustNotBeEmpty: {
    id: 'Pro.FieldMustNotBeEmpty',
    defaultMessage: '{field} must not be empty',
    description: "E.g. 'username must not be empty'",
  },
  featureDisabled: {
    id: 'Pro.FeatureDisabled',
    defaultMessage: 'Feature disabled',
  },
  featureDisabledCopy: {
    id: 'Pro.FeatureDisabledCopy',
    defaultMessage:
      'Sorry!<break></break><break></break>This feature is only enabled for some promoters.<break></break><break></break>Please contact your account manager if you would like to use this feature.<break></break><break></break>If you don’t have an account manager please fill out <supportlink>this form</supportlink>.',
  },
  promoterSettings: {
    id: 'Pro.PromoterSettings',
    defaultMessage: 'Promoter settings',
  },
  banking: {
    id: 'Pro.Banking',
    defaultMessage: 'Banking',
  },
  eventWidget: {
    id: 'Pro.EventWidget',
    defaultMessage: 'Event widget',
  },
  eventWidgetPreview: {
    id: 'Pro.EventWidgetPreview',
    defaultMessage: 'Preview',
  },
  ticketAgreement: {
    id: 'Pro.TicketAgreement',
    defaultMessage: 'Ticket agreement',
  },
  eventRevisions: {
    id: 'Pro.EventRevisions',
    defaultMessage: 'Event Revisions',
  },
  artistsDetected: {
    id: 'Pro.ArtistsDetected',
    defaultMessage: 'Artists detected',
  },
  addAnotherLink: {
    id: 'Pro.AddAnotherLink',
    defaultMessage: 'Add another link',
  },
  addAnotherPromoter: {
    id: 'Dict.AddAnotherPromoter',
    defaultMessage: 'Add another promoter',
  },
  displayedAs: {
    id: 'Pro.DisplayedAs',
    defaultMessage: 'Displayed as',
  },
  mediaLinks: {
    id: 'Pro.MediaLinks',
    defaultMessage: 'Media links',
  },
  mediaExplanation: {
    id: 'Pro.MediaExplanation',
    defaultMessage:
      'Add SoundCloud, YouTube or Mixcloud media links to embed them on your event page.',
  },
  eventListingDate: {
    id: 'Pro.EventListingDate',
    defaultMessage: 'This event will be listed on {date}',
  },
  eventListingTwoDates: {
    id: 'Pro.EventListingtwoDates',
    defaultMessage: 'This event will be listed on {startDate} and {endDate}',
  },
  eventListingMultipleDates: {
    id: 'Pro.EventListingMultipleDates',
    defaultMessage:
      'This event will be listed on {startDate} up to and including {endDate}',
  },
  thisEventIsInThePastWithMessage: {
    id: 'Pro.ThisEventIsInThePastWithMessage',
    defaultMessage: '<bold>This event is in the past:</bold> {message}',
  },
  taggedArtists: {
    id: 'Pro.TaggedArtists',
    defaultMessage: 'Tagged artists',
  },
  eventNameIsRequired: {
    id: 'Pro.EventNameIsRequired',
    defaultMessage: 'Event name is required',
  },
  eventStartDateIsRequired: {
    id: 'Pro.EventStartDateIsRequired',
    defaultMessage: 'Event start date is required',
  },
  eventStartTimeIsRequired: {
    id: 'Pro.EventStartTimeIsRequired',
    defaultMessage: 'Event start time is required',
  },
  eventEndTimeIsRequired: {
    id: 'Pro.EventEndTimeIsRequired',
    defaultMessage: 'Event end time is required',
  },
  eventEndDateIsRequired: {
    id: 'Pro.EventEndDateIsRequired',
    defaultMessage: 'Event end date is required',
  },
  venueIsRequired: {
    id: 'Pro.VenueIsRequired',
    defaultMessage: 'Venue is required',
  },
  countryIsRequired: {
    id: 'Pro.CountryIsRequired',
    defaultMessage: 'Country is required',
  },
  areaIsRequired: {
    id: 'Pro.AreaIsRequired',
    defaultMessage: 'Area is required',
  },
  lineupIsRequired: {
    id: 'Pro.LineupIsRequired',
    defaultMessage: 'Lineup is a required field',
  },
  timeFormatting: {
    id: 'Pro.TimeFormatting',
    defaultMessage: 'Times should be formatted {timeFormat}',
  },
  eventMaxTitleLength: {
    id: 'Pro.EventMaxTitleLength',
    defaultMessage: 'Event name must be shorter than {maxLength} characters',
  },
  tbaVenueMaxLength: {
    id: 'Pro.TbaVenueMaxLength',
    defaultMessage:
      'TBA venue name must be shorter than {maxLength} characters',
  },
  eventMaxLineupLength: {
    id: 'Pro.EventMaxLineupLength',
    defaultMessage: 'Event lineup is too long',
  },
  eventEndBeforeStart: {
    id: 'Pro.EventEndBeforeStart',
    defaultMessage: 'Event end is before event start',
  },
  eventMinLength: {
    id: 'Pro.EventMinLength',
    defaultMessage: 'Event must be at least {minLength} minutes',
  },
  eventTooLong: {
    id: 'Pro.EventTooLong',
    defaultMessage: 'Event must be shorter than {maxDays} days',
  },
  secretVenue: {
    id: 'Pro.SecretVenue',
    defaultMessage: 'Announce venue details to ticket holders via email',
  },
  secretVenueEmailOnSubmit: {
    id: 'Pro.SecretVenueEmailOnSubmit',
    defaultMessage: 'Email ticket holders immediately after their purchase',
  },
  secretVenueEmailMessage: {
    id: 'Pro.SecretVenueEmailMessage',
    defaultMessage: 'Email message',
  },
  dateLabel: {
    id: 'Pro.DateLabel',
    defaultMessage: 'Date',
  },
  timeLabel: {
    id: 'Pro.TimeLabel',
    defaultMessage: 'Time',
  },
  secretVenueEmailMessageBody: {
    id: 'Pro.SecretVenueEmailMessageBody',
    defaultMessage: 'Use this to tell your event attendees about your location',
  },
  emailMessageIsRequired: {
    id: 'Pro.EmailMessageIsRequired',
    defaultMessage: 'An email message is required',
  },
  emailDateIsRequired: {
    id: 'Pro.EmailDateIsRequired',
    defaultMessage: 'An email date is required',
  },
  emailSendTimeIsRequired: {
    id: 'Pro.EmailSendTimeIsRequired',
    defaultMessage: 'Email send time is required',
  },
  secretEventMaxEmailLength: {
    id: 'Pro.SecretEventMaxEmailLength',
    defaultMessage: 'Email too long. Max {maxLength} characters',
  },
  secretEventDateNotInPast: {
    id: 'Pro.SecretEventDateNotInPast',
    defaultMessage: 'Date cannot be in the past',
  },
  secretEventDateNotAfterEventStart: {
    id: 'Pro.SecretEventDateNotAfterEventStart',
    defaultMessage: 'Email time cannot be after event start time',
  },
  secretVenueEmailTicketHolders: {
    id: 'Pro.SecretVenueEmailTicketHolders',
    defaultMessage: 'Email ticket holders',
  },
  secretVenueEmailLater: {
    id: 'Pro.SecretVenueEmailLater',
    defaultMessage: 'On or after specific date',
  },
  secretVenueEmailImmediately: {
    id: 'Pro.SecretVenueEmailImmediately',
    defaultMessage: 'Immediately after ticket purchase',
  },
  taxInvoices: {
    id: 'Pro.TaxInvoices',
    defaultMessage: 'Tax invoices',
  },
  invoiceDownload: {
    id: 'Pro.InvoiceDownload',
    defaultMessage: 'Invoice download',
  },
  exportAudienceData: {
    id: 'Pro.exportAudienceData',
    defaultMessage: 'Export all audience data',
  },
  currency: {
    id: 'Pro.Currency',
    defaultMessage: 'Currency',
  },
  noInvoicesToDisplayHeading: {
    id: 'Pro.NoInvoicesToDisplayHeading',
    defaultMessage: 'There are no invoices to display.',
  },
  noInvoicesToDisplayMessage: {
    id: 'Pro.NoInvoicesToDisplayMessage',
    defaultMessage:
      'Tax invoices are only available for events where Resident Advisor charges a service fee on tickets.',
  },
  eventManagement: {
    id: 'Pro.EventManagement',
    defaultMessage: 'Event management',
  },
  myEvents: {
    id: 'Pro.MyEvents',
    defaultMessage: 'My Events',
  },
  hideTicketFromEventListing: {
    id: 'Pro.HideTicketFromEventListing',
    defaultMessage: 'Hide ticket from event listing',
  },
  endDate: {
    id: 'Pro.EndDate',
    defaultMessage: 'End date',
  },
  includeBookingFee: {
    id: 'Pro.IncludeBookingFee',
    defaultMessage: 'Include booking fee for customer',
  },
  totalPossibleRevenue: {
    id: 'Pro.TotalPossibleRevenue',
    defaultMessage: 'Total possible revenue',
  },
  salesPeriod: {
    id: 'Pro.SalesPeriod',
    defaultMessage: 'Sales period',
  },
  saveAndAddAnotherTicket: {
    id: 'Pro.SaveAndAddAnotherTicket',
    defaultMessage: 'Save and add another ticket',
  },
  salesStart: {
    id: 'Pro.SalesStart',
    defaultMessage: 'Sales start',
  },
  salesEnd: {
    id: 'Pro.SalesEnd',
    defaultMessage: 'Sales end',
  },
  totalEventAllocation: {
    id: 'Pro.TotalEventAllocation',
    defaultMessage: 'Total event allocation',
  },
  createTicket: {
    id: 'Pro.CreateTicket',
    defaultMessage: 'Create ticket',
  },
  editTicket: {
    id: 'Pro.EditTicket',
    defaultMessage: 'Edit ticket',
  },
  yourTickets: {
    id: 'Pro.YourTickets',
    defaultMessage: 'Your tickets',
  },
  onSaleUntilDate: {
    id: 'Pro.OnSaleUntilDate',
    defaultMessage: 'On sale until {date}',
  },
  edit: {
    id: 'Pro.Edit',
    defaultMessage: 'Edit',
  },
  delete: {
    id: 'Pro.Delete',
    defaultMessage: 'Delete',
  },
  stopSales: {
    id: 'Pro.StopSales',
    defaultMessage: 'Stop sales',
  },
  resumeSales: {
    id: 'Pro.ResumeSales',
    defaultMessage: 'Resume sales',
  },
  ticketName: {
    id: 'Pro.ticketName',
    defaultMessage: 'Ticket name',
  },
  toolTipMessage: {
    id: 'Pro.toolTipMessage',
    defaultMessage: 'Please end ticket sales based on your last entry time.',
  },
  toolTipCost: {
    id: 'Pro.toolTipCost',
    defaultMessage: 'Please enter a valid currency format.',
  },
  allocation: {
    id: 'Pro.Allocation',
    defaultMessage: 'Allocation',
  },
  revenuePerTicket: {
    id: 'Pro.revenuePerTicket',
    defaultMessage: 'Revenue per ticket',
  },
  buyerPays: {
    id: 'Pro.buyerPays',
    defaultMessage: 'Buyer pays',
  },
  advancedSettings: {
    id: 'Pro.AdvancedSettings',
    defaultMessage: 'Advanced settings',
  },
  advancedSettingsInfo: {
    id: 'Pro.AdvancedSettingsInfo',
    defaultMessage:
      'This ticket will automatically go on sale on these dates after sales have ended for the selected ticket.',
  },
  whenAreTicketsAvailable: {
    id: 'Pro.WhenAreTicketsAvailable',
    defaultMessage: 'When are tickets available?',
  },
  onSaleWhenSalesEndForThisTicket: {
    id: 'Pro.OnSaleWhenSalesEndForThisTicket',
    defaultMessage: 'On sale when sales end for this ticket',
  },
  onSaleAfter: {
    id: 'Pro.OnSaleAfter',
    defaultMessage: 'On sale after',
  },
  dateAndTime: {
    id: 'Pro.DateAndTime',
    defaultMessage: 'Date & time',
  },
  addOns: {
    id: 'Pro.AddOns',
    defaultMessage: 'Add ons',
  },
  exportData: {
    id: 'Pro.ExportData',
    defaultMessage: 'Export Data',
  },
  printRemittance: {
    id: 'Pro.PrintRemittance',
    defaultMessage: 'Print Remittance',
  },
  promos: {
    id: 'Pro.Promos',
    defaultMessage: 'Promos',
  },
  raTicketScanner: {
    id: 'Pro.RaTicketScanner',
    defaultMessage: 'RA Ticket Scanner',
  },
  sales: {
    id: 'Pro.Sales',
    defaultMessage: 'Sales',
  },
  ticketActivity: {
    id: 'Pro.TicketActivity',
    defaultMessage: 'Ticket Activity',
  },
  seeManagementTools: {
    id: 'Pro.SeeManagementTools',
    defaultMessage: 'See all management tools',
  },
  createYourFirstTicket: {
    id: 'Pro.CreateYourFirstTicket',
    defaultMessage: 'Create your first ticket.',
  },
  createYourFirstTicketMessage: {
    id: 'Pro.CreateYourFirstTicketMessage',
    defaultMessage: 'Tickets for your event will appear here.',
  },
  addYourFirstTicketMessage: {
    id: 'Pro.AddYourFirstTicketMessage',
    defaultMessage: 'Add your first ticket.',
  },
  ticketManagementTools: {
    id: 'Pro.TicketManagementTools',
    defaultMessage: 'Ticket management tools',
  },
  genresExplanation: {
    id: 'Pro.GenresExplanation',
    defaultMessage:
      'Give attendees a flavour of the genres that will be played at the event.',
  },
  submitUpdate: {
    id: 'Pro.SubmitUpdate',
    defaultMessage: 'Submit update',
  },
  ticketManagement: {
    id: 'Pro.TicketManagement',
    defaultMessage: 'Ticket Management',
  },
  statistics: {
    id: 'Pro.Statistics',
    defaultMessage: 'Statistics',
  },
  ticketScannerAndGuestlist: {
    id: 'Pro.TicketScannerAndGuestlist',
    defaultMessage: 'Ticket scanner & guestlist',
  },
  addTickets: {
    id: 'Pro.AddTickets',
    defaultMessage: 'Add tickets',
  },
  addTicket: {
    id: 'Pro.AddTicket',
    defaultMessage: 'Add ticket',
  },
  soldOut: {
    id: 'Pro.SoldOut',
    defaultMessage: 'Sold out',
  },
  hidden: {
    id: 'Pro.Hidden',
    defaultMessage: 'Hidden',
  },
  onSaleDate: {
    id: 'Pro.OnSaleDate',
    defaultMessage: 'On sale {date}',
  },
  salesEndDateReachedDate: {
    id: 'Pro.SalesEndDateReachedDate',
    defaultMessage: 'Sales end date reached, {date}',
  },
  resaleActive: {
    id: 'Pro.ResaleActive',
    defaultMessage: 'RA resale active. {num} tickets in resale queue',
  },
  resaleInactive: {
    id: 'Pro.ResaleInactive',
    defaultMessage: 'RA resale inactive',
  },
  pendingApproval: {
    id: 'Pro.PendingApproval',
    defaultMessage: 'Pending approval',
  },
  onSaleFollowing: {
    id: 'Pro.OnSaleFollowing',
    defaultMessage: 'On sale following {dependencyTicketTitle}',
  },
  salesStopped: {
    id: 'Pro.SalesStopped',
    defaultMessage: 'Sales stopped',
  },
  ticketEndBeforeStart: {
    id: 'Pro.TicketEndBeforeStart',
    defaultMessage: 'Ticket sales end cannot be before the ticket sales start',
  },
  ticketStartAfterEventEnd: {
    id: 'Pro.TicketStartAfterEventEnd',
    defaultMessage: 'Ticket sales start cannot be after the event has ended',
  },
  ticketEndAfterEventEnd: {
    id: 'Pro.TicketEndAfterEventEnd',
    defaultMessage: 'Ticket sales end cannot be after the event has ended',
  },
  ticketSaleLengthTooShort: {
    id: 'Pro.TicketSaleLengthTooShort',
    defaultMessage: 'Ticket sales have to be longer than {minLength} minutes',
  },
  onSaleForDepositUntil: {
    id: 'Pro.OnSaleForDepositUntil',
    defaultMessage:
      'On sale for {depositTicketTitle} ticket holders until {date}',
  },
  onSaleForDepositAt: {
    id: 'Pro.OnSaleForDepositAt',
    defaultMessage: 'On sale for {depositTicketTitle} ticket holders at {date}',
  },
  startDate: {
    id: 'Pro.StartDate',
    defaultMessage: 'Start date',
  },
  ticketStatistics: {
    id: 'Pro.TicketStatistics',
    defaultMessage: 'Ticket Statistics',
  },
  totalTicketsSold: {
    id: 'Pro.TotalTicketsSold',
    defaultMessage: 'Total tickets sold',
  },
  totalTicketsRevenue: {
    id: 'Pro.TotalTicketsRevenue',
    defaultMessage: 'Total tickets revenue',
  },
  userDeleted: {
    id: 'Pro.UserDeleted',
    defaultMessage: 'User deleted',
  },
  additionalRevenueFromResale: {
    id: 'Pro.AdditionalRevenueFromResale',
    defaultMessage: 'Additional revenue from RA Resale',
  },
  price: {
    id: 'Pro.Price',
    defaultMessage: 'Price',
  },
  payable: {
    id: 'Pro.Payable',
    defaultMessage: 'payable',
  },
  paymentDetailsAddedSuccessfully: {
    id: 'Pro.PaymentDetailsAddedSuccessfully',
    defaultMessage: 'Your payment details have been added successfully.',
  },
  promoterManagement: {
    id: 'Pro.PromoterManagement',
    defaultMessage: 'Promoter management',
  },
  addBankDetailsReminder: {
    id: 'Pro.AddBankDetailsReminder',
    defaultMessage:
      'Add your bank details to receive payment from your ticket sales.',
  },
  addBankDetails: {
    id: 'Pro.AddBankDetails',
    defaultMessage: 'Add bank details',
  },
  cannotDeleteInvoicePromoter: {
    id: 'Pro.CannotDeleteInvoicePromoter',
    defaultMessage:
      'Promoter cannot be deleted as they receive payment and the tax invoice.',
  },
  invoiceRecipient: {
    id: 'Pro.InvoiceRecipient',
    defaultMessage: 'Invoice Recipient',
  },
  invoiceRecipientTooltip: {
    id: 'Pro.InvoiceRecipientTooltip',
    defaultMessage:
      'This promoter will receive the invoice for this event. The recipient can only be changed if no tickets have been sold. To do this, contact promotersupport@ra.co',
  },
  guestlist: {
    id: 'Pro.Guestlist',
    defaultMessage: 'Guestlist',
  },
  cannotRemovePromoters: {
    id: 'Pro.CannotRemovePromoters',
    defaultMessage:
      'You do not have the necessary permissions to remove promoters from this event.',
  },
  ticketscanner: {
    id: 'Pro.TicketScanner',
    defaultMessage: 'Ticket Scanner',
  },
  externalTicketImport: {
    id: 'Pro.ExternalTicketImport',
    defaultMessage: 'External ticket import',
  },
  insights: {
    id: 'Pro.insights',
    defaultMessage: 'Insights',
  },

  ticketScannerData: {
    id: 'Pro.TicketScannerData',
    defaultMessage: 'Ticket Scanner Data',
  },
  audienceData: {
    id: 'Pro.AudienceData',
    defaultMessage: 'Audience Data',
  },
  accessToDoorStaff: {
    id: 'Pro.AccessToDoorStaff',
    defaultMessage: 'Access to door staff',
  },
  settings: {
    id: 'Pro.Settings',
    defaultMessage: 'Settings',
  },
  preRegistration: {
    id: 'Pro.PreRegistration',
    defaultMessage: 'Pre-registration',
  },
  presaleReward: {
    id: 'Pro.presaleReward',
    defaultMessage:
      'Reward your most loyal <break></break>customers with early access<break></break>to tickets',
  },
  presaleMeasure: {
    id: 'Pro.presaleMeasure',
    defaultMessage: 'Measure demand for your<break></break>event',
  },
  presaleCollect: {
    id: 'Pro.presaleCollect',
    defaultMessage:
      'Collect customers emails for<break></break>future marketing opportunities',
  },
  presalePageHeader: {
    id: 'Pro.presalePageHeader',
    defaultMessage: 'Pre-sale',
  },
  presaleTickets: {
    id: 'Pro.presalePageHeader',
    defaultMessage: 'Pre-sale tickets',
  },
  presaleEarlyAccess: {
    id: 'Pro.presaleEarlyAccess',
    defaultMessage: 'Get early access to tickets',
  },
  presaleCustomerMessage: {
    id: 'Pro.presaleCustomerMessage',
    defaultMessage:
      'Customers will be emailed a link to access pre-sale tickets prior to tickets going on sale.',
  },
  presaleEmailMessage: {
    id: 'Pro.presaleEmailMessage',
    defaultMessage:
      'Customers will be emailed a link to access pre-sale tickets on {date}',
  },
  signUpForPresale: {
    id: 'Pro.SignUpForPresale',
    defaultMessage: 'Sign up for presale',
  },
  signedUp: {
    id: 'Pro.SignedUp',
    defaultMessage: 'Signed up',
  },
  signedUpForPresale: {
    id: 'Pro.SignedUpForPresale',
    defaultMessage: "You've signed up for presale",
  },
  presaleScheduledEmail: {
    id: 'Pro.presaleScheduledEmail',
    defaultMessage:
      'We’ll email {email} a link to access presale tickets prior to tickets going on sale.',
  },
  view: {
    id: 'Events.View',
    defaultMessage: 'View',
  },
  presaleHasBeenSent: {
    id: 'Pro.presaleHasBeenSent',
    defaultMessage: 'Pre-sale email sent!',
  },
  presaleHasBeenSentMessage: {
    id: 'Pro.presaleHasBeenSentMessage',
    defaultMessage:
      'Customers have been emailed a link to access pre-sale tickets.',
  },
  presaleEvent: {
    id: 'Pro.presaleEvent',
    defaultMessage: 'Presale event',
  },
  presaleEmail: {
    id: 'Pro.presaleEmail',
    defaultMessage: 'Presale has been set up',
  },
  chooseTicketsForPresale: {
    id: 'Pro.ChooseTicketsForPresale',
    defaultMessage: 'Choose tickets for pre-sale',
  },
  onSaleMessage: {
    id: 'Pro.onSaleMessage',
    defaultMessage: 'On sale',
  },
  setEmailSendTime: {
    id: 'Pro.setEmailSendTime',
    defaultMessage: 'Set email send time',
  },
  emailSendTime: {
    id: 'Pro.EmailSendTime',
    defaultMessage: 'Email send time',
  },
  beforeTicketsOnSale: {
    id: 'Pro.beforeTicketsOnSale',
    defaultMessage: 'before tickets on sale',
  },
  timeOfSending: {
    id: 'Pro.TimeOfSending',
    defaultMessage: 'Time of sending',
  },
  setUpPreSale: {
    id: 'Pro.setUpPreSale',
    defaultMessage: 'Set up pre-sale',
  },
  setUpPresaleMessage: {
    id: 'Pro.setUpPresaleMessage',
    defaultMessage: 'To set up pre-sale you need a hidden ticket',
  },
  howPresaleWork: {
    id: 'Pro.howPresaleWork',
    defaultMessage: 'How does pre-sale work?',
  },
  chooseTicket: {
    id: 'Pro.chooseTicket',
    defaultMessage: 'Choose a ticket',
  },
  advanced: {
    id: 'Pro.Advanced',
    defaultMessage: 'Advanced',
  },
  tickets: {
    id: 'Pro.Tickets',
    defaultMessage: 'Tickets',
  },
  ticket: {
    id: 'Pro.Ticket',
    defaultMessage: 'Ticket',
  },
  salesBreakdown: {
    id: 'Pro.SalesBreakdown',
    defaultMessage: 'Sales Breakdown',
  },
  exportAudience: {
    id: 'Pro.ExportAudience',
    defaultMessage: 'Export Audience',
  },
  viewEvent: {
    id: 'Events.ViewEvent',
    defaultMessage: 'View event',
  },
  saveAndContinue: {
    id: 'Pro.SaveAndContinue',
    defaultMessage: 'Save & continue',
  },
  additionalPromoterInfo: {
    id: 'Pro.AdditionalPromoterInfo',
    defaultMessage:
      'To add more information to your promoter profile, go to the Manage Promoters section on the RA Pro homepage',
  },
  promoterCreationTerms: {
    id: 'Pro.PromoterCreationTerms',
    defaultMessage:
      'I confirm that I have read and agreed to the <terms>Terms & Conditions</terms> and I confirm that I am an authorised signatory on behalf of the Promoter',
  },
  createPromoterInfo: {
    id: 'Pro.CreatePromoterInfo',
    defaultMessage:
      'Create a promoter so you can receive payment for your ticket sales',
  },
  termsAndConditions: {
    id: 'Pro.TermsAndConditions',
    defaultMessage: 'Terms & Conditions',
  },
  createPromoterProfile: {
    id: 'Pro.CreatePromoterProfile',
    defaultMessage: 'Create a promoter profile',
  },
  chooseAPromoter: {
    id: 'Pro.ChooseAPromoter',
    defaultMessage: 'Choose a promoter',
  },
  pleaseChooseAPromoter: {
    id: 'Pro.PleaseChooseAPromoter',
    defaultMessage: 'To add tickets, please choose a promoter.',
  },
  choosePromoterInfo: {
    id: 'Pro.ChoosePromoterInfo',
    defaultMessage:
      'The promoter you choose will <bold>receive payment and the tax invoice</bold> for this event.',
  },
  noOfCustomersOnWaitlist: {
    id: 'Pro.noOfCustomersOnWaitlist',
    defaultMessage: 'No. of customers on the waitlist',
  },
  eventPendingApproval: {
    id: 'Pro.EventPendingApproval',
    defaultMessage: 'Event pending approval.',
  },
  success: {
    id: 'Pro.Success',
    defaultMessage: 'Success!',
  },
  eventApproved: {
    id: 'Pro.eventApproved',
    defaultMessage: 'Event Approved.',
  },
  eventPendingApproveBody: {
    id: 'Pro.eventPendingApproveBody',
    defaultMessage: `We aim to review events within 24 hours. Once it's approved, we'll notify you by email.`,
  },
  eventApprovedBody: {
    id: 'Pro.EventApprovedBody',
    defaultMessage:
      'Your event is live and is visible on RA. Add tickets to appear higher up the listings.',
  },
  publicEventApprovedBody: {
    id: 'Pro.publicEventApprovedBody',
    defaultMessage:
      'Your event is visible in the listings and search results. Add tickets to appear higher up the listings.',
  },
  hiddenEventApprovedBody: {
    id: 'Pro.hiddenEventApprovedBody',
    defaultMessage:
      'Your event is accessible via this <supportlink>link</supportlink>. Add tickets to start selling straight away.',
  },
  scheduledEventApprovedBody: {
    id: 'Pro.scheduledEventApprovedBody',
    defaultMessage:
      'Your event will be set to public on {date} at {time}. Add tickets to appear higher up the listings.',
  },
  ticketsInResaleQueue: {
    id: 'Pro.TicketsInResaleQueue',
    defaultMessage: 'No. of tickets in resale queue',
  },
  revenueFromResale: {
    id: 'Pro.RevenueFromResale',
    defaultMessage: 'Includes {resaleRevenue} from resale',
  },
  promoterServiceFeeDiscount: {
    id: 'Pro.PromoterServiceFeeDiscount',
    defaultMessage: 'Includes {discount} discount',
  },
  revenueFromResaleAndpromoterServiceFeeDiscount: {
    id: 'Pro.RevenueFromResaleAndpromoterServiceFeeDiscount',
    defaultMessage:
      'Includes {discount} discount & {resaleRevenue} from resale',
  },

  publicPendingApproval: {
    id: 'Pro.PublicPendingApproval:',
    defaultMessage: 'Public (pending approval)',
  },
  hiddenPendingApproval: {
    id: 'Pro.HiddenPendingApproval:',
    defaultMessage: 'Hidden (pending approval)',
  },
  scheduled: {
    id: 'Pro.Scheduled',
    defaultMessage: 'Scheduled',
  },
  scheduledPendingApproval: {
    id: 'Pro.ScheduledPendingApproval',
    defaultMessage: 'Scheduled (pending approval)',
  },
  eventSetPublicDate: {
    id: 'Pro.EventSetPublicDate',
    defaultMessage: 'Event will be set to public on ',
  },
  eventVisibility: {
    id: 'Pro.EventVisibility',
    defaultMessage: 'Event Visibility',
  },
  public: {
    id: 'Pro.Public',
    defaultMessage: 'Public',
  },
  visibility: {
    id: 'Pro.Visibility',
    defaultMessage: 'Visibility',
  },
  visibilityPublicDescription: {
    id: 'Pro.VisibilityPublicDescription',
    defaultMessage: 'Visible in the listings and in search results',
  },
  visibilityHiddenDescription: {
    id: 'Pro.VisibilityHiddenDescription',
    defaultMessage: 'Accessible via a link',
  },
  scheduleEventDescription: {
    id: 'Pro.ScheduleEventDescription',
    defaultMessage: 'Schedule event to be made public later',
  },
  timeDateBasedOnLocalTime: {
    id: 'Pro.TimeDateBasedOnLocalTime',
    defaultMessage: 'Time and date are based on the local time of your event.',
  },
  eventScheduleDateIsRequired: {
    id: 'Pro.EventScheduleDateIsRequired',
    defaultMessage: 'Event schedule date is required',
  },
  eventScheduleTimeIsRequired: {
    id: 'Pro.EventScheduleTimeIsRequired',
    defaultMessage: 'Event schedule time is required',
  },
  scheduledDate: {
    id: 'Pro.ScheduledDate',
    defaultMessage: 'Scheduled Date',
  },
  scheduledTime: {
    id: 'Pro.ScheduledTime',
    defaultMessage: 'Scheduled Time',
  },
  learnMore: {
    id: 'Pro.LearnMore',
    defaultMessage: 'Learn more',
  },
  bumpYourEvent: {
    id: 'Pro.BumpYourEvent',
    defaultMessage: 'Bump your event to position 3 on the RA Guide',
  },
  setTimes: {
    id: 'Pro.SetTimesTitle',
    defaultMessage: 'Set Times',
  },
  enterSetTimes: {
    id: 'Pro.EnterSetTimes',
    defaultMessage:
      'Enter set times for your attendees to see during your event.',
  },
  setTimesPlaceholder: {
    id: 'Pro.SetTimesPlaceholder',
    defaultMessage: 'Artist',
  },
  availableToAttendees: {
    id: 'Pro.AvailableToAttendees',
    defaultMessage:
      'Available to attendees from the start to the end of your event',
  },
  displayedOnYourEventPage: {
    id: 'Pro.DisplayedOnYourEventPage',
    defaultMessage: 'Displayed on your event page',
  },
  displayedOn: {
    id: 'Pro.displayedOn',
    defaultMessage: 'Displayed on',
  },
  name: {
    id: 'Pro.Name',
    defaultMessage: 'Name',
  },
  viewInQueueIt: {
    id: 'Pro.ViewInQueueIt',
    defaultMessage: 'View in queue-it',
  },
  viewWaitingRoom: {
    id: 'Pro.ViewWaitingRoom',
    defaultMessage: 'View waiting room',
  },
  queues: {
    id: 'Pro.Queues',
    defaultMessage: 'Queues',
  },
  createQueue: {
    id: 'Pro.CreateQueue',
    defaultMessage: 'Create queue',
  },
  createQueuePanelTitle: {
    id: 'Pro.CreatePanelTitle',
    defaultMessage: 'Create your first queue.',
  },
  createQueuePanelInfoText: {
    id: 'Pro.CreateQueuePanelInfoText',
    defaultMessage:
      'Please check queueit in order to view your created queues.',
  },
  createQueueModalTitle: {
    id: 'Pro.CreateQueueModalTitle',
    defaultMessage: 'Create Queue',
  },
  createQueueFormInfo: {
    id: 'Pro.CreateQueueFormInfo',
    defaultMessage: 'Using default settings',
  },
  createQueueFormInfoSubText: {
    id: 'Pro.CreateQueueFormInfoSubText',
    defaultMessage: 'Most settings can only be changed in queue-it',
  },
  queueDisplayName: {
    id: 'Pro.QueueDisplayName',
    defaultMessage: 'Display Name',
  },
  queueEventId: {
    id: 'Pro.QueueEventId',
    defaultMessage: 'Event Id',
  },
  queueStartDate: {
    id: 'Pro.QueueStartDate',
    defaultMessage: 'Start Date',
  },
  queueStartTime: {
    id: 'Pro.QueueStartTime',
    defaultMessage: 'Start Time',
  },
  queueEndDate: {
    id: 'Pro.QueueEndDate',
    defaultMessage: 'End Date',
  },
  queueEndTime: {
    id: 'Pro.QueueEndTime',
    defaultMessage: 'End Time',
  },
  queueCreateNameMustHaveEventIdPrefix: {
    id: 'Pro.QueueCreateNameMustHaveEventIdPrefix',
    defaultMessage: 'Queue name must be prefixed with the event id',
  },
  queueDisplayNameIsRequired: {
    id: 'Pro.QueueDisplayNameIsRequired',
    defaultMessage: 'Queue display name is required',
  },
  queuesCanOnlyBeEditedInQueueIt: {
    id: 'Pro.QueuesCanOnlyBeEditedInQueueIt',
    defaultMessage: 'Queues can only be edited in queue-it',
  },
  viewInQueueItToEditOrDeleteQueues: {
    id: 'Pro.ViewInQueueItToEditOrDeleteQueues',
    defaultMessage: 'View in queue-it to edit or delete queues',
  },
  queuesForYourEventWillAppearHere: {
    id: 'Pro.QueuesForYourEventWillAppearHere',
    defaultMessage: 'Queues for your event will appear here.',
  },
  queueIdIsRequired: {
    id: 'Pro.QueueIdIsRequired',
    defaultMessage: 'Queue Id is required',
  },
  queueId: {
    id: 'Pro.QueueId',
    defaultMessage: 'Queue Id',
  },
  queuePromoCode: {
    id: 'Pro.QueuePromoCode',
    defaultMessage: 'Add promo code',
  },
  queuePromoCodeInfo: {
    id: 'Pro.QueuePromoCodeInfo',
    defaultMessage:
      'Please ensure that your promo code is entered correctly and corresponds to the appropriate ticket tier.',
  },
  promoCode: {
    id: 'Pro.PromoCode',
    defaultMessage: 'Promo code',
  },
  noPromoCode: {
    id: 'Pro.NoPromoCode',
    defaultMessage: '-',
  },
  help: {
    id: 'Pro.Help',
    defaultMessage: 'Help',
  },
  backToRA: {
    id: 'Pro.BackToRA',
    defaultMessage: 'ra.co',
  },
  myRevisions: {
    id: 'Pro.MyRevisions',
    defaultMessage: 'My Revisions',
  },
  autoApproved: {
    id: 'Pro.AutoApproved',
    defaultMessage: 'Auto Approved',
  },
  ticketedEvents: {
    id: 'Pro.TicketedEvents',
    defaultMessage: 'Ticketed Events',
  },
  compareEvents: {
    id: 'Pro.CompareEvents',
    defaultMessage: 'Compare Events',
  },
  contract: {
    id: 'Pro.Contract',
    defaultMessage: 'Contract',
  },
  promoterDetails: {
    id: 'Pro.PromoterDetails',
    defaultMessage: 'Promoter Details',
  },
  permissions: {
    id: 'Pro.Permissions',
    defaultMessage: 'Permissions',
  },
  addUsersToManageEvents: {
    id: 'Pro.AddUsersToManageEvents',
    defaultMessage: 'Add users to help you manage your events',
  },
  permissionsInfoBoxText: {
    id: 'Pro.PermissionsInfoBoxText',
    defaultMessage:
      'Users will have view and edit permissions for all of your events including Ticket Management, Sales Breakdown, Ticket Statistics, Audience Insights and Scanner Data',
  },
  noUsersOnPromoter: {
    id: 'Pro.NoUsersOnPromoter',
    defaultMessage: 'No users have access to your events yet',
  },
  email: {
    id: 'Pro.Email',
    defaultMessage: 'Email address',
  },
  access: {
    id: 'Pro.Access',
    defaultMessage: 'Access',
  },
  addUser: {
    id: 'Pro.AddUser',
    defaultMessage: 'Add user',
  },
  remove: {
    id: 'Pro.Remove',
    defaultMessage: 'Remove',
  },
  aBetaProductBy: {
    id: 'Pro.ABetaProductBy',
    defaultMessage: 'A BETA product by',
  },
  bumpsBillingTermsHeader: {
    id: 'Pro.BumpsBillingTermsHeader',
    defaultMessage: 'Bumps Billing Terms',
  },
  bumpsBillingTermsBody1: {
    id: 'Pro.BumpsBillingTermsBody1',
    defaultMessage:
      'The cost of the bump campaign(s) will be deducted from the ticket revenue from the promoted event. This will happen before the funds are transferred to you post event. The deduction will not exceed the set budget for the campaign (+VAT where applicable).',
  },
  bumpsBillingTermsBody2: {
    id: 'Pro.BumpsBillingTermsBody2',
    defaultMessage:
      "In the case where the cost of the bump campaigns(s) exceed the event's ticket revenue or where no tickets were sold, an invoice with 30-day payment terms will be sent after the promoted event. The invoice will not exceed the set budget for the campaign (+VAT where applicable).",
  },
  bumpsFormInfo: {
    id: 'Pro.BumpsFormInfo',
    defaultMessage:
      'Raising your Cost per Click bid increases the likelihood of your event securing position 3 on the <bold>iOS RA Guide</bold>. You only pay when users click on your ad.',
  },
  bumpsFormInfoWeb: {
    id: 'Pro.BumpFormInfoWeb',
    defaultMessage:
      'Raising your Cost per Click bid increases the likelihood of your event securing position 3 on ra.co and the iOS RA Guide. You only pay when users click on your ad.',
  },
  costPerClick: {
    id: 'Pro.CostPerClick',
    defaultMessage: 'Cost per click ({currency})',
  },
  adsBudget: {
    id: 'Pro.AdsBudget',
    defaultMessage: 'Ads budget ({currency})',
  },
  bumpsToolTip: {
    id: 'Pro.BumpsToolTip',
    defaultMessage:
      'CPC (Cost Per Click). This means that you pay per click, and not per impression. For example, if one ad is running at £0.50 CPC and another is running at £0.70 CPC then it is more likely that the ad with the higher amount will be displayed first.',
  },
  bumpVatTax: {
    id: 'Pro.BumpVatTax',
    defaultMessage: '+20% VAT',
  },
  totalClicks: {
    id: 'Pro.TotalClicks',
    defaultMessage: 'Total possible clicks',
  },
  bumpsAcknowledgement: {
    id: 'Pro.BumpsAcknowledgement',
    defaultMessage:
      'I acknowledge that the budget for this campaign will be covered by revenue generated from ticket sales.',
  },
  bumpsConsent: {
    id: 'Pro.BumpsConsent',
    defaultMessage:
      "I consent to the <terms>billing terms</terms> and agree to Resident Advisor's <ad>advertising terms</ad>.",
  },
  minBumpBudget: {
    id: 'Pro.MinBumpBudget',
    defaultMessage: 'Minimum ads budget is {minimumBudget}',
  },
  minBumpCpc: {
    id: 'Pro.MinBumpCpc',
    defaultMessage: 'Minimum cost per click is {minimumCpc}',
  },
  maxBumpCpc: {
    id: 'Pro.MaxBumpCpc',
    defaultMessage: 'Maximum cost per click is {maximumCpc}',
  },
  bumpCpcLessThanBudget: {
    id: 'Pro.BumpCpcLessThanBudget',
    defaultMessage: 'Cost per click must be less than the ads budget',
  },
  bumpBudgetLessThanSpend: {
    id: 'Pro.bumpBudgetLessThanSpend',
    defaultMessage: 'You cannot reduce the budget below {budget}',
  },
  startingFrom: {
    id: 'Pro.StartingFrom',
    defaultMessage: 'Starting from {value}',
  },
  youDontHaveAccessToThis: {
    id: 'Pro.YouDontHaveAccessToThis',
    defaultMessage: "You don't have access to this",
  },
  onlyInvoicePromoterCanCreateBump: {
    id: 'Pro.OnlyInvoicePromoterCanCreateBump',
    defaultMessage:
      'Only the promoter who is invoiced for the event can create a Bump campaign.',
  },
  bumpsDataUpdatedFrequency: {
    id: 'Pro.BumpsDataUpdatedFrequency',
    defaultMessage: 'Data updated every 15 mins',
  },
  bumpCampaignInProgress: {
    id: 'Pro.BumpCampaignInProgress',
    defaultMessage: 'Bump campaign in progress',
  },
  bumpCampaignInProgressBody: {
    id: 'Pro.BumpCampaignInProgressBody',
    defaultMessage:
      "If you're the highest bidder your event will be bumped to position 3 on the iOS RA Guide.",
  },
  bumpCampaignInProgressBodyWeb: {
    id: 'Pro.BumpCampaignInProgressBodyWeb',
    defaultMessage:
      "If you're the highest bidder your event will be bumped to position 3 on ra.co and the iOS RA Guide.",
  },
  bumpCampaignSpentBudget: {
    id: 'Pro.BumpSpentBudget',
    defaultMessage: "You've successfully spent your allocated budget",
  },
  bumpCampaignSpentBudgetBody: {
    id: 'Pro.BumpSpentBudgetBody',
    defaultMessage: 'Edit your campaign to add budget.',
  },
  bumpCampaignComplete: {
    id: 'Pro.BumpFinished',
    defaultMessage: 'Event finished and campaign complete',
  },
  bumpCampaignCompleteBody: {
    id: 'Pro.BumpFinishedBody',
    defaultMessage:
      "We'll deduct payment for the total spend from ticket sales.",
  },
  stats: {
    id: 'Pro.Stats',
    defaultMessage: 'Stats',
  },
  ticketScannerTitle: {
    id: 'Pro.ticketScannerTitle',
    defaultMessage: 'TICKET SCAN ACCESS FOR DOOR STAFF',
  },
  ticketScannerAccessInfoTitle: {
    id: 'Pro.ticketScannerAccessInfoTitle',
    defaultMessage: 'Add scan access for your door staff',
  },
  ticketScannerAccessInfoBody: {
    id: 'Pro.ticketScannerAccessInfoBody',
    defaultMessage:
      'Add PINs (event passwords) for your door staff to be able to check-in event attendees using RA Pro Ticket Scan app (<link>iOS</link> / Android).',
  },
  ticketScannerNoPins: {
    id: 'Pro.ticketScannerNoPins',
    defaultMessage: 'No PINS have been created for your event yet.',
  },
  ticketScannerStats: {
    id: 'Pro.ticketScannerStats',
    defaultMessage:
      'Access detailed scanning stats for <bold>{eventName}</bold>',
  },
  ticketScannerAppDownload: {
    id: 'Pro.ticketScannerAppDownload',
    defaultMessage:
      'Download the updated version of RA Pro Ticket Scan to start accepting visitors at your events.',
  },

  ticketScannerStatsTitle: {
    id: 'Pro.ticketScannerStatsTitle',
    defaultMessage: 'SCAN STATISTICS',
  },
  ticketScannerDownloadTitle: {
    id: 'Pro.ticketScannerDownloadTitle',
    defaultMessage: 'DOWNLOAD THE APP',
  },
  addPINButtonTitle: {
    id: 'Pro.addPINButtonTitle',
    defaultMessage: 'Add PIN',
  },
  openStatsButtonTitle: {
    id: 'Pro.openStatsButtonTitle',
    defaultMessage: 'Open statistics',
  },
  displayCodeSnippet: {
    id: 'Pro.DisplayCodeSnippet',
    defaultMessage:
      'Display events on your website by embedding a code snippet into your website’s HTML.',
  },
  displayCodeSnippetBoxText: {
    id: 'Pro.DisplayCodeSnippetBoxText',
    defaultMessage:
      "We automatically keep your event listings up to date, so there's no need for manual updates once the code is embedded.",
  },
  embedCode: {
    id: 'Pro.EmbedCode',
    defaultMessage: 'Embed code',
  },
  copyToClipboard: {
    id: 'Pro.CopyToClipboard',
    defaultMessage: 'Copy to clipboard',
  },
  copied: {
    id: 'Pro.Copied',
    defaultMessage: 'Copied!',
  },
  vatNumber: {
    id: 'Pro.VATNumber',
    defaultMessage: 'VAT Number',
  },
  vatNumberLabel: {
    id: 'Pro.VATNumberLabel',
    defaultMessage: 'VAT/IVA/GST number',
  },
  transparentBackground: {
    id: 'Pro.TransparentBackground',
    defaultMessage: 'Transparent background',
  },
  ticketingNotSupported: {
    id: 'Pro.TicketingNotSupported',
    defaultMessage: 'RA does not currently support ticketing in this country.',
  },
  backgroundColour: {
    id: 'Pro.BackgroundColour',
    defaultMessage: 'Background colour',
  },
  textColour: {
    id: 'Pro.TextColour',
    defaultMessage: 'Text colour',
  },
  area: {
    id: 'Pro.Area',
    defaultMessage: 'Area',
  },
  overview: {
    id: 'Pro.Overview',
    defaultMessage: 'Overview',
  },
  overviewInfoPanelTitle: {
    id: 'Pro.OverviewInfoTitle',
    defaultMessage: "Increase your event's visibility and sell more tickets",
  },
  overviewInfoPanelBody: {
    id: 'Pro.OverviewInfoBody',
    defaultMessage:
      'Complete your event setup to rank higher in the listings —<break></break>add genres, tag artists, upload artwork and more to optimise your event.',
  },
  readOurGuide: {
    id: 'Pro.ReadOurGuide',
    defaultMessage: 'Read our guide',
  },
  linkToOldWidget: {
    id: 'Pro.LinkToOldWidget',
    defaultMessage:
      'Looking for the old widget? You can access it <link>here</link> until December 2024, after which it will become unsupported',
  },
  theme: {
    id: 'Pro.Theme',
    defaultMessage: 'Theme',
  },
  dark: {
    id: 'Pro.Dark',
    defaultMessage: 'Dark',
  },
  light: {
    id: 'Pro.Light',
    defaultMessage: 'Light',
  },
  custom: {
    id: 'Pro.Custom',
    defaultMessage: 'Custom',
  },
  customise: {
    id: 'Pro.Customise',
    defaultMessage: 'Customise',
  },
  alreadyAddedTicketsToEvent: {
    id: 'Pro.AlreadyAddedTicketsToEvent',
    defaultMessage:
      "You've already added tickets to your event, make sure to adjust their on-sale times if needed.",
  },
  youHave3DaysToChangeEventDates: {
    id: 'Pro.YouHave3DaysToChangeEventDates',
    defaultMessage:
      'You have 3 days to change the event date and time once created, provided no tickets have been sold.',
  },
})

export default messages
